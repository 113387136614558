import React, { useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import NumberFormat from "./../../../components/number_format";
import moment from "moment";
import { Link } from "react-router-dom";
const BuyingPage = (props) => {
  const { data, statusCheck } = props;

  useEffect(() => {
    data?.map((item) => {
      statusCheck(item.status);
    });
  }, [data]);
  return (
    <div className="row">
      <Card className="p-0">
        <Card.Body>
          <Table>
            <thead>
              <tr className="text-center">
                <th>
                  <div>Mã đơn hàng</div>
                </th>
                <th>
                  <div>Số lượng</div>
                </th>
                <th>
                  <div>Thời gian đặt</div>
                </th>
                <th>
                  <div>Tổng tiền</div>
                </th>
                <th>
                  <div>Trạng thái</div>
                </th>
              </tr>
            </thead>
            {data.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <tbody id="invoice-listing" key={index}>
                    <tr className="text-center">
                      <td className="sku-detail product-detail">
                        { item?.invoice_user_id !== undefined ?
                          <Link
                            to={`/nguoi-dung/thong-tin-don-mua-hoan-tra/${item.id}`}
                          >
                            {item.invoice_user_sku}
                          </Link>
                        :
                          <Link
                            to={`/nguoi-dung/thong-tin-don-hang-mua/${item.id}`}
                          >
                            {item.sku}
                          </Link>
                        }
                      </td>
                      <td>{item.quantity}</td>
                      <td>
                        <small>
                        { item?.invoice_user_id !== undefined ? moment(item.invoice_user_created_at).format("DD-MM-YYYY") : moment(item.created_at).format("DD-MM-YYYY") }
                        </small>
                      </td>
                      <td>
                        <NumberFormat>{ item?.invoice_user_id !== undefined ? item.total_price : item.price_total }</NumberFormat>
                        <span className="ml-1 red-clr font-italic">VNĐ</span>
                      </td>
                      <td>
                        <div className={`mt-1 badge badge-${ statusCheck(item.status).color }`} >
                          {statusCheck(item.status).text}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr >
                  <td className="empty-data">Không có đơn hàng</td>
                </tr>
              </tbody>
            )}
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BuyingPage;
