import React, { useEffect, useState } from 'react';
import { getPopupAdvertisements } from '../../apis/banner';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { saveBannerOrder, fetchBanner } from 'actions/banner';

const Banner = ({ location }) => {
  const dispatch = useDispatch();
  const order = useSelector(state => state.banner.order);
  const [listBanner, setListBanner] = useState([]);
  const [isCloseBanner, setIsCloseBanner] = useState(false);
  const module_paths = {
    market: '/cho-2nong',
    market_price: '/modules/gia-ca-thi-truong',
    post: '/',
    video: '/videos',
    article: '/tin-tuc',
    technical_process: '/quy-trinh-ky-thuat',
    weather: '/modules/thoi-tiet',
  };

  const onCloseBanner = () => setIsCloseBanner(true);

  const getKeyByValue = (object, value) => {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null; // Trả về null nếu không tìm thấy giá trị trong đối tượng
  };
  const module = getKeyByValue(module_paths, location.pathname);

  const fetchBanners = async () => {
    setIsCloseBanner(true);
    const { data } = await getPopupAdvertisements({
      show_position: Object.keys(module_paths).find(key => module_paths[key] === location.pathname),
    });
    let banners = data.data;
    if (banners.length > 1) {
      const bannerIndex = order.filter(key => key.id === banners[0].id);
      if (bannerIndex) {
        banners = banners.filter(banner => !bannerIndex.some(item => item.id === banner.id));
        setListBanner(banners);
        dispatch(saveBannerOrder({ module: module, id: banners[0].id }));
      }
    } else setListBanner(banners);

    setIsCloseBanner(false);
  };

  useEffect(() => {
    if (module) {
      fetchBanners();
    } else setIsCloseBanner(true);
  }, [location.pathname]);
  
  return (
    <>
      {!isCloseBanner && listBanner?.length > 0 && (
        <div className="row position-relative d-flex justify-content-center ">
          <div
            className="col-md-9 col-xs-12 col-lg-8 col-sm-12
            position-fixed mx-auto"
            style={{
              ...{ [listBanner?.[0]?.location]: 0 },
              height: '100px',
              zIndex: '10000000',
            }}
          >
            <Link to={`/quang-cao/${listBanner?.[0]?.id}`} state={listBanner?.[0]?.id}>
              <img src={listBanner?.[0]?.image} alt="banner" className="w-100 h-100" />
            </Link>
            <div  
              className="d-block position-absolute pointer"
              style={{
                top: '5px',
                right: '20px',
                zIndex: '100000000',
                background: '#e5e5e5',
                padding: '3px',
              }}
              onClick={() => onCloseBanner()}
            >
              <span className="icon-close d-block"></span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
