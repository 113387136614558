import React, { useEffect, useState }from "react";
import AddMetaTags from "../../components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { Card, Form } from "react-bootstrap";
import { getListShipping } from "../../apis/shipping";
import {invoiceDetailPublic} from 'apis/invoice';
import { get_user } from 'lib';
import {useParams } from "react-router-dom";
import FormVietTelPost from "./components/formViettlePost";
import ListProducts from "./components/listProducts";
import FormGHTK from "./components/formGHTK";
// import ServiceModal from "./components/ServiceModal";

const CreateShipping = () => {
  const { invoiceId } = useParams();
  const [shippingUnits, setShippingUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [pickupOption, setPickupOption] = useState('cod');
  const current_user = get_user()
  const [error, setError] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [urlImage, setUrlImage] = useState(null);
  const [invoiceDetail, setInvoiceDetail] = useState(null);


  async function getInvoicePublic(){
    const response = await invoiceDetailPublic(invoiceId);
    return response;
  }

  useEffect(() => {
    getInvoicePublic().then((response) => {
      setInvoiceDetail(response.data.data);
      setError(false);
    }).catch((err) => {
      setError(true)
    });
  }, [invoiceId]);

  const uploadImage = (e) => {
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);
    setImagePreview(file);
    setUrlImage(url);
  };

  useEffect(() => {
    const fetchShippingUnits = async () => {
      const { data } = await getListShipping(current_user?.shop?.id);
      const activeUnits = data.data.filter(unit => unit.available_status === 'active');
      setShippingUnits(activeUnits);
      setSelectedUnit(activeUnits[0]?.shipping_name);
    };

    fetchShippingUnits();
  }, []);

  const handleChange = (event) => {
    setSelectedUnit(event.target.value);
  };

  const handlePickupChange = (event) => {
    setPickupOption(event.target.value);
  };

  return (
    <div>
      <AddMetaTags title={"Thiết lập đơn vị vận chuyển"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="cont-center saved-items">
            <Form>
              <Card className="p-0 mt-3">
                <Card.Body>
                  <h3>Đơn vị vận chuyển <span className="red-clr">*</span></h3>
                  <div className="ml-2">
                    {shippingUnits.map((unit) => (
                      <div className="mt-2" key={unit.id}>
                        <label className={`radio-button-cus ${selectedUnit === unit.shipping_name ? 'selected' : ''}`}>
                          <input
                            className="mr-2 radio-button-custom"
                            type="radio"
                            id={`shipping-unit-${unit.id}`}
                            name="shippingUnit"
                            value={unit.shipping_name}
                            onChange={handleChange}
                            checked={selectedUnit === unit.shipping_name}
                          />
                          <span htmlFor={`shipping-unit-${unit.id}`} className={`label-radio-button ${selectedUnit === unit.shipping_name ? 'selected' : ''}`}></span>
                          {unit.shipping_name}
                        </label>
                      </div>
                    ))}
                  </div>
                  <hr className="bg-secondary"/>
                  <div className="ml-4">
                    <select className="select-custom" value={pickupOption} onChange={handlePickupChange} style={{borderColor: "#D9D9D9"}}>
                      <option value="cod">Lấy tận nơi</option>
                      <option value="post">Gửi bưu cục</option>
                    </select>
                  </div>
                </Card.Body>
              </Card>
              <Card className="p-0 mt-2">
                <Card.Body>
                  { selectedUnit === "Viettel Post" ? (
                    <FormVietTelPost
                      invoiceDetail= {invoiceDetail}
                      urlImage={urlImage}
                      imagePreview={imagePreview}
                      uploadImage={uploadImage}
                      invoiceId={invoiceId}
                      pickOption={pickupOption}
                      shippingUnitId={shippingUnits.find(s => s.shipping_code?.toLowerCase() === "vtp")?.id || 0}
                    />
                  ) : (
                    <FormGHTK
                      invoiceDetail= {invoiceDetail}
                      urlImage={urlImage}
                      imagePreview={imagePreview}
                      uploadImage={uploadImage}
                      invoiceId={invoiceId}
                      pickOption={pickupOption}
                      shippingUnitId={shippingUnits.find(s => s.shipping_code?.toLowerCase() === "ghtk")?.id || 0}
                    />
                  )}
                  <ListProducts
                    invoiceDetail= {invoiceDetail}
                  />
                </Card.Body>
              </Card>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateShipping;
