import axiosService from "services/axiosService";
import { DOMAIN, PAGE, PER_PAGE } from "constants";
import qs from "query-string";
import { get_user } from "lib";

const url_home = "api/v2/home";
export const getPosts = (params) => {
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_home}/page${query}`, isAuth);
};

export const getPostsAfterDeleteComment = () => {
  return axiosService.get(`${DOMAIN}/${url_home}/page`);
};

export const getHighlightPosts = (params = { page: 1, limit: 5 }) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_home}/highlight_posts${query}`);
};

const url = "api/v2/posts";
export const getUserPosts = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`, true);
};
// api share article && video to post homepage
export const createPost = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.post(`${DOMAIN}/${url}${query}`, true);
};

const url_account = "api/v2/account";
export const likePost = (params) => {
  return axiosService.post(`${DOMAIN}/${url_account}/like_item`, params);
};

export const unlikePost = (params) => {
  return axiosService.post(`${DOMAIN}/${url_account}/unlike`, params);
};

export const likeComment = (params) => {
  return axiosService.post(`${DOMAIN}/${url_account}/like_item`, params);
};

export const unlikeComment = (params) => {
  return axiosService.post(`${DOMAIN}/${url_account}/unlike`, params);
};

export const sharePost = (params) => {
  return axiosService.post(`${DOMAIN}/${url_account}/create_share`, params);
};

export const getFavoritesPosts = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_account}/like_posts${query}`, true);
};

export const getPostCatalogues = () => {
  return axiosService.get(`${DOMAIN}/${url}/post_catalogues`);
};

export const getMetaTags = (url_page, postId = null) => {
  return axiosService.get(`${DOMAIN}/api/v2/meta_tags?domain=${url_page}&post_id=${postId}`);
};

export const addPost = (data) => {
  return axiosService.post(`${DOMAIN}/${url}`, data.formData);
};

export const getPostId = (id) => {
  return axiosService.get(`${DOMAIN}/${url}/${id}`);
};

export const deletePost = (id) => {
  return axiosService.post(`${DOMAIN}/${url}/${id}`);
};

export const getPostDetail = (id) => {
  return axiosService.get(`${DOMAIN}/${url}/${id}`, true);
};

export const reportPost = (params) => {
  let { id } = params;
  return axiosService.post(`${DOMAIN}/${url}/${id}/warning_post`, params);
};

export const fetchPickTop = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }

  return axiosService.get(`${DOMAIN}/${url}/pick_top${query}`, true);
};

export const reportImage = (params) => {
  let id = params.id;
  let img_id = params.img_id;

  return axiosService.post(`${DOMAIN}/${url}/${id}/warning_image/${img_id}`, params);
};
