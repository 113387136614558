import { Fragment } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from 'pages/home/index';
import Forums from 'pages/forums/index';
import About from 'components/about';
import Contact from 'components/contact';
import TwoNongMarket from 'pages/market/index';
import ProductDetail from 'pages/ProductDetail/index';
import Auth from 'components/auth';
import Profile from 'pages/profile/index';
import PostOfUser from 'pages/postOfUser/index';
import Articles from 'pages/articles/index';
import NotFound from 'components/not_found';
import PrivacyPolicy from 'components/privacy_policy';
import TermsCondition from 'components/terms_condition';
import ArticleDetail from 'pages/articleDetail/index';
import Diagnostic from 'containers/diagnostic';
import ProductOfUser from 'pages/productOfUser/index';
import ProductCatalogues from 'pages/productCatalogues/index';
import Favorites from 'containers/favorites';
import Videos from 'pages/videos/index';
import UserProfile from 'containers/user_profile';
import MarketPrice from 'pages/marketPrice/index';
import MarKetPriceDetail from 'pages/marketPriceDetail/index';
import ContributeForm from 'pages/contribute_form/index';
import NPK from '../pages/NPK/index';
import DiagnosticDetail from 'components/diagnostic_detail';
import CallExpert from 'pages/callExpert/index';
import Sitemap from 'components/sitemap';
import PostDetail from 'pages/postDetail/index';
import PrivateRoutes from 'services/privateRouter';
import ManagerComment from 'pages/managerWarnings/index';
import UpdatePassword from '../pages/updatePassword/index.js';
import Weather from 'pages/weather/index';
import ModalViewImagePage from '../pages/ModalViewImagePage';
import DetailPage from '../pages/DetailPage';
import PostDetailProvider from 'containers/PostDetailProvider';
// truy xuat ngon goc
import HomeTraceabilites from 'pages/traceability';
import ListTraceabilites from 'pages/traceability/components/listTraceabilites';
import NewTraceability from 'pages/traceability/components/newTraceability';
import CodeTraceabilites from 'pages/traceability/components/partials/codeTraceability';
import DetailsTraceabilities from 'pages/traceability/components/partials/detailsTraceability';
// quy trinh ky thuât
import TechnicalProcess from 'pages/technical_process';
import ContributeTechnicalProcess from 'pages/technical_process/components/contributeTechnical';
import CatalogueDetail from 'pages/technical_process/components/CatalogueDetail';
//weather
import WeatherSetting from 'pages/weatherSetting/weatherSetting';
import NewSettingWeather from 'pages/weatherSetting/new';
import ManageProduct from '../pages/ManageProduct';
import AddEditProduct from '../pages/AddEditProduct';
import EditWeatherSchedule from 'pages/weatherSetting/edit';
// shop quà tặng
import ShopGift from 'pages/GiftStore/index';
import PointsTransaction from 'pages/pointsTransaction/storeGiftDetail';
import GiftReceiveDetail from '../pages/GiftReceiveDetail';
//user Albums
import UserAlbums from '../pages/UserAlbums';
import Cart from 'pages/Cart/cart';
// Invoices Market
import InvoicesIndex from '../pages/InvoicesMarket';
//detail invoice
import DetailInvoice from '../pages/InvoiceDetail/Detail';
import ManageContribute from '../pages/manageContribute/index.jsx';
// invoice detail public
import DetailInvoiceNotAuthen from '../pages/InvoiceDetail/DetailInvoiceNotAuthen';
//business association
import BusinessAction from '../pages/BusinessAssociation/BusinessAction/index';
import BusinessProduct from '../pages/BusinessAssociation/BusinessProduct/index';
import AddEditBusinessProduct from '../pages/BusinessAssociation/BusinessProduct/AddEdit';
import BusinessInvoice from '../pages/BusinessAssociation/BusinessInvoice/index';
import BusinessInvoiceDetail from '../pages/BusinessAssociation/BusinessInvoiceDetail/index';
import BusinessEmployee from '../pages/BusinessAssociation/BusinessEmployee';
import BusinessDetail from 'pages/BusinessAssociation/BusinessAssociationDetail/index';
import TechnicalProcessDetail from '../pages/TechnicalProcessDetail/index.jsx';
import VideoDetail from '../pages/VideoDetail';
import TextToSpeech from '../pages/TextToSpeech/index.jsx';
import Banner from '../components/Banner';
import BannerDetail from '../pages/BannerDetail/BannerDetail';
import AllEnterPrises from '../pages/Enterprises';
import EnterpriseDetail from '../pages/EnterpriseDetail';

function Routers() {
  const location = useLocation();
  const state = location.state;
  return (
    <Fragment>
      <Routes location={state?.backgroundLocation || location}>
        {/* <Route path="/dien-dan" element={<Forums />} />
         <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Forums />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cho-2nong" element={<TwoNongMarket />}>
          <Route path="nong-san" element={<TwoNongMarket />} />
          <Route path="vat-tu-nong-nghiep" element={<TwoNongMarket />} />
          <Route path="si-nong-nghiep" element={<TwoNongMarket />} />
          <Route path="nha-san-xuat" element={<TwoNongMarket />} />
        </Route>
        <Route path="/danh-sach-doanh-nghiep" element={<AllEnterPrises />} />
        <Route path="/doanh-nghiep/:slug" element={<EnterpriseDetail />} />
        <Route path="/san-pham/:productId" element={<ProductDetail />} />
        <Route path="/san-pham/danh-muc/:catalogueId" element={<ProductCatalogues />} />
        <Route path="/tin-tuc/:articleId" element={<ArticleDetail type="Article" />} />
        <Route path="/tin-tuc" element={<Articles />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/diagnostics" element={<Diagnostic />} />
        <Route path="/diagnostics/:diagnosticsId" element={<DiagnosticDetail />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/short_videos/:shortVideoId" element={<VideoDetail />} />
        <Route path="/quang-cao/:bannerId" element={<BannerDetail />} />
        <Route path="/videos/:articleId" element={<ArticleDetail type="Video" />} />
        <Route path="/modules/npk" element={<NPK />} />
        <Route path="/modules/gia-ca-thi-truong" element={<MarketPrice />} />
        <Route path="/modules/thong-tin-gia-ca-thi-truong/:marketId" element={<MarKetPriceDetail />} />
        <Route path="/modules/gia-ca-thi-truong/dong-gop" element={<ContributeForm />} />
        <Route path="/modules/goi-chuyen-gia" element={<CallExpert />} />
        <Route path="/modules/giong-doc" element={<TextToSpeech />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/p/:postId" element={<PostDetail />} />
        <Route path="/p" element={<PostDetailProvider />}>
          <Route path=":postId/i/:imageId" element={<ModalViewImagePage />} />
          {/* <Route path=":postId" element={<DetailPage />} /> */}
          {/* <Route path=":postId/i/:imageId" element={<ModalViewImagePage />} /> */}
        </Route>
        {/* truy xuat ngon goc */}

        <Route path="/modules/truy-xuat-nguon-goc" element={<HomeTraceabilites />} />
        <Route path="/modules/truy-xuat-nguon-goc/danh-sach" element={<ListTraceabilites />} />
        <Route path="/modules/truy-xuat-nguon-goc/nhap-ma" element={<CodeTraceabilites />} />
        <Route path="/modules/truy-xuat-nguon-goc/tao-moi" element={<NewTraceability />} />
        <Route path="/modules/truy-xuat-nguon-goc/chinh-sua" element={<NewTraceability />} />
        <Route path="/modules/truy-xuat-nguon-goc/:traceabilityId" element={<DetailsTraceabilities />} />
        <Route>
          <Route path="/quy-trinh-ky-thuat" element={<TechnicalProcess />} />
          <Route path="/dong-gop-quy-trinh-ky-thuat" element={<ContributeTechnicalProcess />} />
          <Route path="/quy-trinh-ky-thuat/danh-muc/:categoryId" element={<CatalogueDetail />} />
          <Route path="/quy-trinh-ky-thuat/chi-tiet/:technicalProcessId" element={<TechnicalProcessDetail />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="/about" element={<About />} />
          <Route path="/nguoi-dung">
            <Route path="/nguoi-dung/trang-ca-nhan" element={<Profile />} />
            <Route path="/nguoi-dung/bai-viet" element={<PostOfUser />} />
            <Route path="/nguoi-dung/san-pham" element={<ProductOfUser />}>
              <Route index element={<ManageProduct />} />
              <Route path="new" element={<AddEditProduct />} />
              <Route path="edit/:productId" element={<AddEditProduct />} />
            </Route>
            <Route path="/nguoi-dung/san-pham-yeu-thich" element={<Favorites />} />
            <Route path="/nguoi-dung/:userId" element={<UserProfile />} />
            <Route path="/nguoi-dung/quan-ly-binh-luan" element={<ManagerComment />} />
            <Route path="/nguoi-dung/cap-nhat-mat-khau" element={<UpdatePassword />} />
            <Route path="/nguoi-dung/thiet-lap-thong-bao" element={<WeatherSetting />}></Route>
            <Route path="/nguoi-dung/don-hang-cua-toi" element={<InvoicesIndex />} />
            <Route path="/nguoi-dung/thong-tin-don-hang-ban/:invoiceId" element={<DetailInvoice type="seller" />} />
            <Route path="/nguoi-dung/thong-tin-don-hang-mua/:invoiceId" element={<DetailInvoice type="buyer" />} />
            <Route path="/nguoi-dung/quan-ly-dong-gop" element={<ManageContribute />} />
          </Route>
          <Route path="/gio-hang" element={<Cart />} />
        </Route>
        <Route path="/nguoi-dung/thiet-lap-thong-bao/tao-moi" element={<NewSettingWeather isEditMode={false} />} />
        <Route
          path="/nguoi-dung/thiet-lap-thong-bao/chinh-sua/:weatherId"
          element={<EditWeatherSchedule isEditMode={true} />}
        />
        <Route path="/"></Route>
        <Route path="/modules/thoi-tiet" element={<Weather />} />
        <Route path="/shop-qua-tang" element={<ShopGift />} />
        <Route path="/nhat-ky-doi-qua" element={<PointsTransaction />} />
        <Route path="/chi-tiet-nhan-thuong" element={<GiftReceiveDetail />} />
        <Route path="/nguoi-dung/albums/:albumId" element={<UserAlbums />} />

        <Route path="/don-hang/:invoiceId" element={<DetailInvoiceNotAuthen />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/lien-ket-danh-nghiep">
            <Route path="chuc-nang/:businessId" element={<BusinessAction />} />
            <Route path="san-pham/:businessId" element={<BusinessProduct />} />
            <Route path="san-pham/:businessId/tao-moi" element={<AddEditBusinessProduct />} />
            <Route path="san-pham/chinh-sua/:productId/business/:businessId" element={<AddEditBusinessProduct />} />
            <Route path="don-hang/danh-sach-don-hang/:businessId" element={<BusinessInvoice />} />
            <Route path="thong-tin-don-hang/:invoiceId/business/:businessId" element={<BusinessInvoiceDetail />} />
            <Route path="nhan-vien/:businessId" element={<BusinessEmployee />} />
            <Route path="thong-tin-danh-nghiep/:businessId" element={<BusinessDetail />} />
          </Route>
        </Route>
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route path="/p" element={<PostDetailProvider />}>
            <Route path=":postId/i/:imageId" element={<ModalViewImagePage />} />
          </Route>
        </Routes>
      )}
      <Banner location={location} />
    </Fragment>
  );
}

export default Routers;
